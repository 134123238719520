import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { Button, Paper, Text } from '@mantine/core'

type SentryFallbackProps = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError: () => void;
}

export function SentryFallback({ error, resetError }: SentryFallbackProps) {
  const reportError = useCallback(() => {
    Sentry.captureException(error)
    resetError()
  }, [error, resetError])

  return (
    <Paper p='lg'>
      <Text
        size='lg'
        weight={700}
        mb='md'
      >
        You have encountered an error
      </Text>
      <Text>
        {error.message}
      </Text>
      <Button
        mt='xl'
        onClick={reportError}
      >
        Report error
      </Button>
    </Paper>
  )
}
