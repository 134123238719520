import {PopupDefaultContent, getPopupDefaultButtons} from '@appscience/popups'
import {Checkbox, TextInput} from '@mantine/core'
import {compose, keys} from 'ramda'
import React, {useState} from 'react'
import {TypedObject, trulyObject} from '@appscience/utils'
import {SimpleTableLabel} from '@appscience/simple-table'

interface ColumnInfo {
  id: string,
  title: string,
}

interface PreExcelExportPopupProps {
  columns: Array<ColumnInfo>,
  canSelectColumns?: boolean,
  defaultSelectedIds?: Array<string>,
  onChange?: (ids: Array<string>) => void,
  onExport: (filename: string, columnIds: Array<string>) => void,
  closeFn: () => void,
}

export function PreExcelExportPopup(props: PreExcelExportPopupProps) {
  return <PopupDefaultContent
    title='Export to XSLX'
    createContent={() => <Content {...props} />}
    closeFn={props.closeFn}
  />
}

function getInitSelectedIds(columns: Array<ColumnInfo>, initColumnIds: Array<string>): TypedObject<string, boolean> {
  const idsSet = new Set(initColumnIds)
  return columns.reduce((acc: TypedObject<string, boolean>, item) => {
    acc[item.id] = idsSet.size === 0 || idsSet.has(item.id)
    return acc
  }, {})
}

function Content({
  columns,
  canSelectColumns,
  defaultSelectedIds = [],
  onExport,
  onChange,
  closeFn,
}: PreExcelExportPopupProps) {
  const [filename, setFilename] = useState('')
  const [selectedColumnIds, setSelectedColumnIds] = useState(getInitSelectedIds(columns, defaultSelectedIds))

  function onSuccess() {
    const file = filename.endsWith('.xlsx') ? filename : filename + '.xlsx'
    onExport(file, compose(keys, trulyObject)(selectedColumnIds))
    closeFn()
  }

  function onChangeHandler(id: string) {
    const newValue = {...selectedColumnIds, [id]: !selectedColumnIds[id]}
    setSelectedColumnIds(prev => ({...prev, [id]: !prev[id]}))
    onChange && onChange(compose(keys, trulyObject)(newValue))
  }

  return (
    <div>
      {canSelectColumns && columns.length > 0 && (
        <div className='grid gap-x-4 items-center grid-cols-2 mb-2'>
          {columns.map(x => (
            <Checkbox
              key={x.id}
              label={x.title}
              name={x.id}
              checked={selectedColumnIds[x.id]}
              onChange={() => onChangeHandler(x.id)}
              className='px-3 py-2 duration-200'
            />
          ))}
        </div>
      )}
      <TextInput
        label={<SimpleTableLabel label='File name' />}
        value={filename}
        onChange={event => setFilename(event.currentTarget.value)}
      />
      <div className='flex justify-end mt-3 space-x-2'>
        {getPopupDefaultButtons({
          success: {
            text: 'Export',
            onClick: onSuccess,
          },
          cancel: { onClick: closeFn },
        })}
      </div>
    </div>
  )
}
