import {isNullable} from '../check'
import {Nullable} from '../types'

interface CsvColumnInfo<COLUMN_ID extends string> {
  id: COLUMN_ID,
  title: string,
}

interface DownloadAsCsvParams<COLUMN_ID extends string, ITEM> {
  columns: Array<CsvColumnInfo<COLUMN_ID>>,
  data: Array<ITEM>,
  contentAccessor: (item: ITEM, columnId: COLUMN_ID) => string,
  filename: string,
}

export function downloadAsCsv<COLUMN_ID extends string, ITEM>(params: DownloadAsCsvParams<COLUMN_ID, ITEM>) {
  const csvData = makeCsvData(params)
  const csvFile = new Blob([csvData], { type: 'text/csv' })
  const downloadLink = document.createElement('a')

  downloadLink.style.display = 'none'
  downloadLink.download = params.filename
  downloadLink.href = window.URL.createObjectURL(csvFile)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

interface MakeCsvDataParams<COLUMN_ID extends string, ITEM> {
  columns: Array<CsvColumnInfo<COLUMN_ID>>,
  data: Array<ITEM>,
  contentAccessor: (item: ITEM, columnId: COLUMN_ID) => string,
}

function makeCsvData<COLUMN_ID extends string, ITEM>({
  data,
  columns,
  contentAccessor,
}: MakeCsvDataParams<COLUMN_ID, ITEM>) {
  return data.reduce((csvString, rowItem) => (
    csvString +
    columns.map(({ id }) => escapeCsvCell(contentAccessor(rowItem, id))).join(',') +
    '\r\n'
  ), columns.map(({ title }) => escapeCsvCell(title)).join(',') + '\r\n')
}

function escapeCsvCell(cell: Nullable<string>) {
  if (isNullable(cell)) {
    return ''
  }
  const sc = cell.toString().trim()
  if (sc === '' || sc === '""') {
    return sc
  }
  if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
    return '"' + sc.replace(/"/g, '""') + '"'
  }
  return sc
}
