import {logError} from '../logger'
import {Nullable} from '../types'

export function isNumber<T>(value: T|number|string): value is number|string {
  if (typeof value === 'number') {
    return true
  }
  if (typeof value === 'string') {
    // @ts-ignore
    return !isNaN(parseFloat(value)) && !isNaN(value - 0)
  }
  return false
}


export function formatMoney(money: number|string): string|false {
  if (!isNumber(money)) {
    logError(`It's not number. Value: ${money}`)
    return false
  }

  const num = typeof money === 'number' ? money : Number(money)
  const str = num.toFixed(2).toString()
  const [firstPart, secondPart] = str.split('.')
  if (firstPart.length <= 3) {
    return `${firstPart},${secondPart}`
  }

  const remains = firstPart.length % 3
  let formattedFirstPart = firstPart.substring(0, remains)
  for (let i = remains; i < firstPart.length; i++) {
    formattedFirstPart += (i - remains) % 3 === 0 ? ` ${firstPart[i]}` : firstPart[i]
  }
  return `${formattedFirstPart},${secondPart}`
}

export function numberInputParser(x: string|undefined): string {
  const result = x ? x.replaceAll(/\s*/g, '').match(/^\d+(?:[.,](?:\d+)?)?/) : null
  return result ? result[0].replace(/,/, '.') : ''
}

export function numberInputFormatter(x: string|undefined): string {
  return x && !Number.isNaN(parseFloat(x)) ? x.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
}

export function nullableSum(...x: Array<Nullable<number>>): number {
  return x.reduce((acc: number, num) => acc + (num || 0), 0)
}

export function safeSum(...args: Array<unknown>): number {
  return args.reduce((acc: number, value) => {
    if (typeof value === 'number') {
      return acc + value
    }
    logError(`safeSum() error. Value is not number: ${value}`)
    return acc
  }, 0)
}

export function presiceNumber(value: number, precision: number): number {
  return Number(value.toFixed(precision))
}

/** @description Input: '2+2,5+3.5' Output: 8 */
export function calcSumExpression(formula: string): number {
  return formula.replaceAll(/,/g, '.').split('+').reduce((acc, item) => acc + Number(item), 0)
}