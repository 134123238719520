import {logError} from './logger'
import {Nullable, DefaultLoadingState} from './types'

function checkNever(value: never, msg = `Unhandled value: ${JSON.stringify(value)}`): void {
  return logError(msg)
}

export function isDef<T>(v: T|undefined): v is T {
  return v !== undefined
}

export function isNullable<T>(v: Nullable<T>): v is undefined|null {
  return v == null
}

export function isLoading(state: DefaultLoadingState): boolean {
  return state === 'loading'
}

export function isSaving(state: DefaultLoadingState): boolean {
  return state === 'saving'
}

export {
  checkNever,
}