export function logError(message?: string) {
  console.error(message || 'Logger catch error')
}

export function assert<T>(condition: T, message = 'Assertion failed'): T {
  !condition && logError(message)
  return condition
}

export function verify<T>(condition: (T|null|undefined), message?: string): T {
  assert(condition, message)
  return condition as T
}