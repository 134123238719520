import {mode, joinStrings} from '@appscience/utils'
import {Text, createStyles} from '@mantine/core'
import React from 'react'

interface LabelProps {
  label: string,
  required?: boolean,
  icon?: React.ReactNode,
  className?: string,
}

const useStyles = createStyles(({ colors, colorScheme }) => ({
  label: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 600,
    color: mode(colors.gray[6], colors.gray[6])(colorScheme),
  },
  asterisk: {
    lineHeight: 0,
    color: mode(colors.red[5], colors.red[5])(colorScheme),
    fontSize: '14px',
  },
}))


export function SimpleTableLabel({
  label,
  required,
  icon,
  className,
}: LabelProps): JSX.Element {
  const { classes } = useStyles()

  const labelComponent = <label className={joinStrings(classes.label, className)}>
    {label}
    {required && <Text component='span' className={classes.asterisk}>{' *'}</Text>}
  </label>

  if (icon) {
    return <div className='flex items-center space-x-1'>
      {labelComponent}
      {icon}
    </div>
  }

  return labelComponent
}