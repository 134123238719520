import {isNullable} from '../check'
import {TypedObject} from '../types'

/** @description Возвращает неглубокую копию объекта, отчищенную от null, undefined и false свойств */
export function cleanObject<T extends object, K extends keyof T>(obj: T): Record<K, any> {
  const newObj = {} as Record<K, NonNullable<T[K]>>
  Object.entries(obj).forEach(([key, value]) => {
    if (!isNullable(value)) {
      newObj[key as K] = value as NonNullable<T[K]>
    }
  })
  return newObj
}

/** @description Возвращает неглубокую копию объекта, отчищенную от falses values свойств */
export function trulyObject<T extends object, K extends keyof T>(obj: T): Record<K, any> {
  const newObj = {} as Record<K, NonNullable<T[K]>>
  Object.entries(obj).forEach(([key, value]) => {
    if (value) {
      newObj[key as K] = value as NonNullable<T[K]>
    }
  })
  return newObj
}


export function filterObject<K extends PropertyKey, T>(obj: TypedObject<K, T>, cb: (v: T, k: K) => boolean): TypedObject<K, T> {
  const newObj = {} as TypedObject<K, T>
  Object.entries(obj).forEach(([key, value]) => {
    if (cb(value as T, key as K)) {
      newObj[key as K] = value as T
    }
  })
  return newObj
}

export function updateObject<O extends object, K extends keyof O>(o: O, key: K, value: O[K]) {
  o[key] = value
}