import {Button} from '@mantine/core'
import {RangeCalendar} from '@mantine/dates'
import {useState} from 'react'

interface DateFilterProps {
  value: FilterValue,
  confirmValue: (v: [Date, Date]|null) => void,
}

type FilterValue = [Date|null, Date|null]

//TODO:procurement завести Q&A на открытие страницы с выбранной датой
export function DateRangePopover({
  value,
  confirmValue,
}: DateFilterProps) {
  const [dateValue, setDateValue] = useState<FilterValue>(value)
  const [startDate, endDate] = dateValue

  const onApply = () => {
    confirmValue(startDate
      ? [startDate, endDate || startDate]
      : null,
    )
  }

  return <div className='p-3'>
    <RangeCalendar
      onChange={setDateValue}
      value={dateValue}
      allowSingleDateInRange
    />
    <div className='flex pt-3 pl-0 justify-between'>
      <Button
        variant='light'
        onClick={() => {
          setDateValue([null, null])
        }}
        className='bg-transparent'
        disabled={!dateValue.every(Boolean)}
      >
        Reset
      </Button>
      <Button onClick={onApply}>
        Ok
      </Button>
    </div>
  </div>
}