import { isNullable } from '@appscience/utils'

interface CsvColumnInfo<COLUMN_ID extends string> {
  id: COLUMN_ID,
  title: string,
}

interface MakeCsvDataParams<COLUMN_ID extends string, ITEM> {
  columns: Array<CsvColumnInfo<COLUMN_ID>>,
  data: Array<ITEM>,
  contentAccessor: (item: ITEM, columnId: COLUMN_ID) => string,
}

export function makeCsvData<COLUMN_ID extends string, ITEM>({
  data,
  columns,
  contentAccessor,
}: MakeCsvDataParams<COLUMN_ID, ITEM>): string {
  return data.reduce((csvString, rowItem) => (
    csvString +
    columns.map(({ id }) => escapeCsvCell(contentAccessor(rowItem, id))).join(',') +
    '\r\n'
  ), columns.map(({ title }) => escapeCsvCell(title)).join(',') + '\r\n')
}

function escapeCsvCell(cell: Nullable<string>) {
  if (isNullable(cell)) {
    return ''
  }
  const sc = cell.toString().trim()
  if (sc === '' || sc === '""') {
    return sc
  }
  if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
    return '"' + sc.replace(/"/g, '""') + '"'
  }
  return sc
}

export function downloadAsExcel(excelData: Blob, filename: string): void {
  const downloadLink = document.createElement('a')

  downloadLink.style.display = 'none'
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(excelData)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  URL.revokeObjectURL(downloadLink.href)
  document.body.removeChild(downloadLink)
}
