export enum CountryEnum {
  Usa = 'USA',
  Bulgaria = 'Bulgaria',
  Spain = 'Spain',
  Switzerland = 'Switzerland',
  Italy = 'Italy',
  Russia = 'Russia',
  China = 'China',
  India = 'India',
  France = 'France',
  Uk = 'UK',
  Poland = 'Poland',
  Germany = 'Germany',
  Canada = 'Canada',
}

// see https://catamphetamine.gitlab.io/country-flag-icons/3x2/
export const enum CountryFlagIconsCodeEnum {
  Usa = 'US',
  Bulgaria = 'BG',
  Spain = 'ES',
  Switzerland = 'CH',
  Italy = 'IT',
  Russia = 'RU',
  China = 'CN',
  India = 'IN',
  France = 'FR',
  Uk = 'GB',
  Poland = 'PL',
  Germany = 'DE',
  Canada = 'CA',
}

export type CountryIcon = {
  countryFlagIconCode: CountryFlagIconsCodeEnum;
  title: string;
}

const COUNTRY_ICONS: Record<CountryEnum, CountryIcon> = {
  [CountryEnum.Usa]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Usa,
    title: 'United States',
  },
  [CountryEnum.Bulgaria]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Bulgaria,
    title: 'Bulgaria',
  },
  [CountryEnum.Spain]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Spain,
    title: 'Spain',
  },
  [CountryEnum.Switzerland]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Switzerland,
    title: 'Switzerland',
  },
  [CountryEnum.Italy]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Italy,
    title: 'Italy',
  },
  [CountryEnum.Russia]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Russia,
    title: 'Russia',
  },
  [CountryEnum.China]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.China,
    title: 'China',
  },
  [CountryEnum.India]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.India,
    title: 'India',
  },
  [CountryEnum.France]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.France,
    title: 'France',
  },
  [CountryEnum.Uk]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Uk,
    title: 'UK',
  },
  [CountryEnum.Poland]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Poland,
    title: 'Poland',
  },
  [CountryEnum.Germany]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Germany,
    title: 'Germany',
  },
  [CountryEnum.Canada]: {
    countryFlagIconCode: CountryFlagIconsCodeEnum.Canada,
    title: 'Canada',
  },
}

export const hasCountryIcon =
  (countryCode: string): boolean =>
    Object
      .values(CountryEnum)
      .includes(countryCode as CountryEnum)

export const getCountryIcon =
  (countryCode: CountryEnum): CountryIcon => COUNTRY_ICONS[countryCode]
