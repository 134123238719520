//Notification Error
import {notification} from 'antd'
import React from 'react'
import {PlusOutlined} from '@ant-design/icons'

export const notificationError = (message: string) => {
  notification.error({
    message,
  })
}

//Notification Error
export const notificationSuccess = (message: string, duration = 1.5) => {
  notification.success({
    message,
    duration,
  })
}

//Notification Count
export const notificationCount = (message: string, duration = 1.5) => {
  notification.success({
    message,
    duration,
    icon: <><PlusOutlined className='text-success'/></>,
  })
}
