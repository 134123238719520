import {isNumber} from './byDataTypes'

type CompareReturn = 0|-1|1

export function caseInsensitiveCompare (a: string, b: string): CompareReturn {
  const aString = a.toLowerCase()
  const bString = b.toLowerCase()
  return aString > bString ? 1 : (aString < bString ? -1 : 0)
}

export function defaultCompare<T>(a: T, b: T): CompareReturn {
  return a > b ? 1 : (a < b ? -1 : 0)
}

export function safeCompare<T>(a: T, b: T): CompareReturn {
  if (typeof a === 'string' && typeof b === 'string') {
    if (isNumber(a) && isNumber(b)) {
      return defaultCompare(Number(a), Number(b))
    }
    return caseInsensitiveCompare(a, b)
  }
  return defaultCompare(a, b)
}