/** @description Генерация числа в полуинтервале [start, end) */
export function generateRandomNumber(start = 0, end = 1000) {
  return start + Math.floor(Math.random() * (end - start))
}

export function generateRandomDate(start = new Date(2020), end = new Date()) {
  return new Date(generateRandomNumber(start.getTime(), end.getTime()))
}

export function getRandomItem<T>(array: Array<T>): T {
  return array[generateRandomNumber(0, array.length)]
}

export function delayResolve<T = void>(time: number, data: T): Promise<T> {
  return new Promise(resolve => setTimeout(() => resolve(data), time))
}